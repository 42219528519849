<template>
  <!-- Coming soon page-->
  <section style="padding: 1rem;">
    <b-row>
      <b-col lg="12">
        <SkeletonMyBenefitsTable v-if="isLoadingBenefits"/>
        <div v-else>
          <CurrentBalance
            :currentYear="currentYear"
            @set-is-loading-detail-benefit="setIsLoadingDetailBenefit"
            @set-year="setYear"
            @get-benefits-by-contract="getBenefitsByContractAndYear"
          />
		  <ModalBenefits :isTakingAChoice="isTakingAChoice" :selectedYear="yearToReview" :period="period" :adminResponse="adminResponse" @send-choice="sendChoice"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" v-if="isLoadingDetailBenefit">
        <div class="text-center mt-2" >
          <b-spinner style="width: 3rem; height: 3rem;" /><br>
          <strong>{{$t('Loading details')}}</strong>
        </div>
      </b-col>
      <Benefits v-else/>
    </b-row>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/owners/mixins/utils'
import CurrentBalance from '@/modules/owners/components/myBenefits/CurrentBalance'
import Filters from '@/modules/owners/components/myBenefits/Filters'
import Benefits from '@/modules/owners/components/myBenefits/Benefits'
import ModalBenefits from '@/modules/owners/components/myBenefits/ModalBenefits'
import SkeletonMyBenefitsTable from '@/modules/owners/components/skeletons/SkeletonMyBenefitsTable'
import { showAlertMessage, formatDate } from '@/helpers/helpers'

export default {
	mixins:[utils],
	components: {
		ModalBenefits,
		SkeletonMyBenefitsTable,
		CurrentBalance,
		Filters,
		Benefits
	},
	async created(){
		await this.getBenefitsByContractAndYear()
		await this.checkIfOwnerAgreedBenefitsBalance()
	},
	mounted(){
	},
	data() {
		return {
			isLoadingBenefits: false,
			isLoadingDetailBenefit: false,
			currentYear: new Date().getFullYear(),
			yearToReview: new Date().getFullYear(),
			period: {
				periodRangeEnd: null,
				periodRangeStart: null
			},
			adminResponse: null,
			isTakingAChoice: null
		}
	},
	computed: {
		...mapState('owners',[ 'benefitsByContract', 'benefitDetailsTransaccion']),
		...mapState('auth', ['user', 'myContracts']),
	},
	methods:{
		...mapActions('owners', ['getBenefitsByContract','getDetailsTransactionsBenefit', 'fetchOwnerTransactionsAgreement', 'setOwnerTransactionsAgreement', 'getGenericParameters']),
		...mapMutations('owners', ['setBenefitsToContractSelected','setBenefitDetailTransaccion']),
		async getBenefitsByContractYear(){
			const { id } = this.myContracts.contractSelected // id del contrado seleccionado
			if( !this.benefitsByContract.length ){
				this.isLoadingBenefits = true
				const benefits = await this.getBenefitsByContract({id, year:this.currentYear}) // TODO: cambiar con el id del contracto seleccionado
				this.setBenefitsToContractSelected(benefits)
				this.isLoadingBenefits = false
				if( this.benefitsByContract.length > 0 ){
					this.isLoadingDetailBenefit = true
					const details = await this.getDetailsTransactionsBenefit({id:this.benefitsByContract[0].id})
					this.setBenefitDetailTransaccion(details)
					this.isLoadingDetailBenefit = false
				}
			}
		},
		setIsLoadingDetailBenefit(bool){
			this.isLoadingDetailBenefit = bool
		},
		setYear(year){
			this.currentYear = year
		},
		async getBenefitsByContractAndYear(){
			this.setBenefitsToContractSelected([])
			this.setBenefitDetailTransaccion([])
			await this.getBenefitsByContractYear()
		},
		async checkIfOwnerAgreedBenefitsBalance(){
			const { id, membershipcode } = this.myContracts.contractSelected
			const membershipParams = await this.getGenericParameters(15)
			const validMemberships = membershipParams?.value?.split(';')

			if (validMemberships?.includes(membershipcode) && this.benefitsByContract.length > 0){
				const {status, data, message} = await this.fetchOwnerTransactionsAgreement({idContract: id})
				if (status) {
					this.period = data?.period || null
					this.adminResponse = data?.adminResponse || null
					this.yearToReview = formatDate({date: data.period.periodRangeStart, toFormat: 'YYYY'})
					if (data?.showModal) this.$root.$emit('bv::show::modal', 'transacction-agreement-modal')
				}
			}
		},
		async sendChoice(data){
			const response = await this.setOwnerTransactionsAgreement(data)
			const choice = data.choice === '1' ? this.$t('acepted') : this.$t('rejected')
			if (response?.status) showAlertMessage(choice, 'CheckIcon', this.$t('transactions choiced option', {choice: choice.toLowerCase()}), 'success', 4000, 'bottom-right')
			else showAlertMessage(this.$t('something failed'), 'CheckIcon', this.$t('Sorry, please try again later'), 'warning', 4000, 'bottom-right')
			this.$root.$emit('bv::hide::modal', 'transacction-agreement-modal')
			return true
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<template>
	<b-card no-body>
		<div class="p-1">
			<b> {{ $t('Select a year')}}</b>
			<b-row>
				<b-col>
					<div class="header__year">
						<b-form-select v-model="selectedYear" :options="years" @change="handlerYearBenefit" class="header__select"/>
						<b-button class="btn" variant="primary" @click="searchBenefitsByYear(selectedYear)" > {{ $t('Search')}} </b-button>
					</div>
				</b-col>
				<b-col>
					<div class="header__year float-right">
						<b-button
							v-if="hasBenefits"
							class="mr-1"
							variant="primary"
							:href="`${authURL}FivesClub/benefitbalance/${myContracts.contractSelected.id}/${selectedYear}/`"
							target="_blank"
							rel="noopener noreferrer"
						><feather-icon icon="FileTextIcon" /> {{$t('Download')}}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
		<b-table
			:items="benefitsByContract"
			responsive
			:fields="fields"
			class="mb-0"
			:tbody-tr-class="rowClass"
			show-empty
			:empty-text="$t('There are no records to show')"
		>
			<template #cell(benefit)="row"> {{ benefitName(row.item) }} </template>
			<template #cell(actions)="row">
				<b-button size="sm" @click="showDetail(row.item)" variant="primary" >
					<feather-icon icon="EyeIcon" size="12" /> {{$t('Show detail')}}
				</b-button>
			</template>
			<!-- views -->
			<template #cell(quantity)="row">
				<span>{{row.item.quantity}}</span>
			</template>
		</b-table>
	</b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/owners/mixins/utils'
import { formatDate } from '@/helpers/helpers'

export default {
	mixins: [utils],
	props:{
		currentYear:{
			type: Number,
			required: true
		},
	},
	mounted(){
		this.selectedYear = this.currentYear
	},
	data(){
		return {
			selectedYear: null,
			authURL: process.env.VUE_APP_IMG_SRC_API,
			fields: [
				{ key: 'benefit', label: this.$t('Benefit') },
				{ key: 'initialdate', label: this.$t('Initial date'), formatter: value => {
				return this.formDate(value, 'YYYY-MM-DD')
				}},
				{ key: 'enddate', label: this.$t('End date'), formatter: value => {
				return this.formDate(value, 'YYYY-MM-DD')
				}},
				{ key: 'quantity', label: this.$t('Quantity') },
				{ key: 'used', label: this.$t('Used') },
				{ key: 'balance', label: this.$t('Balance')},
				{ key: 'actions', label: this.$t('Actions')},
			],
		}
	},
	computed: {
		...mapState('owners',[ 'benefitsByContract']) ,
		...mapState('auth', ['user', 'myContracts']),
		languageUser(){
			const initial = 'English'
			return this.user.languageName == 'English' ? initial : 'Spanish'
		},
		hasBenefits(){
			return this.benefitsByContract?.length > 0
		},
		years(){
			const years = []
			const currentYear = new Date().getFullYear()
			for (let i = currentYear - 2; i < currentYear + 1; i++) {
				years.push(i + 1)
			}
			return years
		},
	},
	methods:{
		...mapActions('owners', ['getDetailsTransactionsBenefit','pushActionsService']),		
		...mapMutations('owners',[ 'setBenefitSelected','setBenefitDetailTransaccion']),
		async showDetail(item){
			this.$emit('set-is-loading-detail-benefit', true)
			const itemSelectedPrevious = this.benefitsByContract.find( benefit => benefit.isSelected )
			// el benefit actual seleccionado se vuelve false
			const payload = { idBenefit: itemSelectedPrevious.id, bool: false }
			this.setBenefitSelected(payload)
			// el nuevo benefit actual seleccionado se vuelve true
			const newSelected = {idBenefit: item.id, bool: true}
			this.setBenefitSelected(newSelected)
			const details = await this.getDetailsTransactionsBenefit({id: item.id})
			const items = details.filter( benefit => benefit.statusTransactions !== 'Cancelado')
			this.setBenefitDetailTransaccion(items)
			await this.pushData('onClick', 'getDetailsTransactionsBenefit', 'getDetailsTransactionsBenefit', this.$route.name ) // push a back del registro			
			this.$emit('set-is-loading-detail-benefit', false)
		},
		async searchBenefitsByYear(year){
			this.selectedYear = year
			await this.pushData('onClick', 'getBenefitsByContract', 'getBenefitsByContract', this.$route.name ) // push a back del registro
			this.$emit('get-benefits-by-contract')
		},
		handlerYearBenefit(year){
			this.$emit('set-year', year)
		},
		formDate(date, format){
			return formatDate({date, language: this.user?.languageName, format })
		},
		benefitName(payload){
			const benefit = this.languageUser == 'Spanish' ? payload?.benefit : payload?.benefitEnglish
			if (benefit){
				const name = benefit?.split(' | ')
				return name?.length > 1 ? name[1] : name[0]
			} else return ''
		},			
	}
}
</script>

<style scoped>
.header__year{
    display: flex;
}
.header__select{
    max-width: 150px;
    margin-right: 1rem;
}
</style>
<template>
  <b-row>
    <b-col md="2">        
        <b-form-group label="From">                         
            <b-form-input  type="month" v-model="dateInit">
            </b-form-input>           
        </b-form-group >
    </b-col>
    <b-col md="2">
        <b-form-group label="To">               
            <b-form-input  type="month" v-model="dateEnd">
            </b-form-input>           
        </b-form-group >
    </b-col>
    <b-col md="2">        
        <label>Type</label>
        <b-input-group >
            <b-form-select>
                <option value="">choose type</option>                
                <option value="Tranfers per contract">Tranfers per contract</option>                
            </b-form-select>
            <b-input-group-append>
                <b-button variant="primary">
                    <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </b-col>    
  </b-row>
</template>

<script>

export default {
    data(){
        return {
            dateInit:'',
            dateEnd:'',
        }
    },
    computed:{
        currentYear(){
            const date = new Date();
            // adjust 0 before single digit date
            const day = ("0" + date.getDate()).slice(-2);
            // current month
            const month = ("0" + (date.getMonth() + 1)).slice(-2)
                // current year
            const year = date.getFullYear()

            return year + "-" + month + "-" + day
        }
    }
}
</script>
<template>
  <b-col cols="12" xl="12" md="12" >
    <b-card no-body >
      <b-card-body v-if="benefitSelected">
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <strong>{{ $t('Initial date')}} <font-awesome-icon icon="arrow-right" /> {{ $t('End date')}}: </strong>
            <span> {{formDate(benefitSelected.initialdate, 'YYYY-MM-DD')}} <font-awesome-icon icon="arrow-right" /> {{formDate(benefitSelected.enddate, 'YYYY-MM-DD')}}</span>
          </div>
          <div>
            <strong>{{ $t('Benefit')}}: </strong>
            <span>{{ languageUser == 'Spanish' ? benefitSelected.benefit :benefitSelected.benefitEnglish}} </span>
          </div>
          <div>
            <strong>{{ $t('Quantity')}}: </strong>
            <span>{{benefitSelected.quantity}}</span>
          </div>
          <div>
            <strong> {{ $t('Used')}}: </strong>
            <span >{{benefitSelected.used}}</span>
          </div>
          <div>
            <strong>{{ $t('Balance')}}: </strong>
              <span>{{benefitSelected.balance}}</span>
          </div>
        </div>
      </b-card-body>

      <div v-if="benefitDetailsTransaccion.length">
        <FiltersBenfits
          :perPage="perPage"
          :perPageOptions="perPageOptions"
          @change-per-page="changePerPage"
          v-if="benefitDetailsTransaccion.length"
        />
        <b-table
          :items="detailsTransactions"
          :fields="tableColumns"
          responsive
          class="mb-0"
          show-empty
          :empty-text="$t('There are no records to show')"
        >

          <template #cell(credit)="row">
            <span>{{ row.item.typeTransactions == 'Abono' ? row.item.quantity : '0'}}</span>
          </template>

          <template #cell(consumption)="row">
            <span>{{ row.item.typeTransactions == 'Cargo' ? row.item.quantity : '0'}}</span>
          </template>

          <template #cell(quantity)="row">
            <span>{{row.item.quantity}}</span>
          </template>

          <template #cell(createdBy)="row">
            <span>{{row.item.userName}}</span>
          </template>
        </b-table>
        <PaginationTable
            :currentPage="currentPage"
            :totalRows="benefitDetailsTransaccion.length"
            :perPage="perPage"
            @change-page="changePage"
            v-if="benefitDetailsTransaccion.length"
          />
      </div>
      <div class="m-1" v-else>
        <b-alert variant="danger" show >
            <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('There are no details')}} </div>
        </b-alert>
      </div>
    </b-card>
  </b-col>
</template>

<script>

import { mapState } from 'vuex'
import FiltersBenfits from '@/modules/owners/components/myBenefits/FiltersBenefits'
import PaginationTable from '@/modules/owners/components/myBenefits/PaginationTable'
import { formatDate } from '@/helpers/helpers'

export default {
  components: {
    FiltersBenfits,
    PaginationTable
  },
  data(){
    return {
      optionsDateFormat: {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric"
      },
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refProfitsListTable : null,
      isLoadingMyProfits: false,
      tableColumns: [
        { key: 'createdate', label: this.$t('Registration'), formatter: value => {
          return this.formDate(value)
        }},
        { key: 'operationdate', label: this.$t('Operation Date'), formatter: value => {
          return this.formDate(value, 'YYYY-MM-DD')
        }},
        { key: 'credit', label: this.$t('Credit') },
        { key: 'consumption', label: this.$t('Consumption') },

        { key: 'invoice', label: 'POS' },
        { key: 'notes', label: this.$t('Notes') },

        // { key: 'statusTransactions', label: 'Status' },
        { key: 'createdBy', label: this.$t('Created by') },
        // { key: 'cancelBy', label: 'Cancelled by' },
        // { key: 'quantity', label: 'Quantity' },
      ],
    }
  },
  computed: {
    ...mapState('owners',[ 'benefitsByContract', 'benefitDetailsTransaccion']),
    ...mapState('auth', ['user']),
    detailsTransactions(){
      const items = this.benefitDetailsTransaccion
      return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
    benefitSelected(){
      const benefitSelected =  this.benefitsByContract.find( benefit => benefit.isSelected )
      return benefitSelected ? benefitSelected : null
    },
    languageUser(){
      const initial = 'English'
      return this.user.languageName == 'English' ? initial : 'Spanish'
    },

  },
  methods: {
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    formDate(date, format){
      return formatDate({date, language: this.user?.languageName, format })
    }
  }
}
</script>


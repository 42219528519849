<template>
  <b-row class="p-1">
    <b-col md="2">
      <!-- <label>Entries</label> -->
      <v-select
        v-model="porPagina"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector"
        @input="changePerPage"
      />  
    </b-col>
    
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props:{
    perPage:{
      type: Number,
      required: true,
    },
    perPageOptions:{
      type: Array,
      required: true,
    },
            
  },
  components: {
    vSelect,        
  },
  data() {
    return {      
      porPagina: this.perPage,                                   
    }
  },
  methods:{
    changePerPage( perPage ){
      this.$emit('change-per-page', perPage)   
      this.porPagina = perPage     
    },    
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>